:root {
  --white-color: #ffffff;
  --primary-white-color: #eeeeee;
  --primary-dark-color: #2a2a2a;

  /*accent colors*/
  --orange--accent-color: #fe5a1d;
  --purple--accent-color: #7257e0;
  --magento--accent-color: #c72db6;
  --lime--accent-color: #bbe137;
  --winterfresh--accent-color: #add5d0;
  --dc--accent-color: #732255;

  /*font colors*/
  --primary-font-color: var(--primary-white-color);
  --dark-font-color: var(--primary-dark-color);
  --secondary-font-color: #d1d1d1;
  --disabled-font-color: #7e7e7e;

  /*button colors*/
  --orange-button-color: #e54a25;
  --orange-button-color-hover: #b73b1e;
  --orange-button-color-disabled: #732513;
}

html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  overflow: hidden;
  box-sizing: border-box;
}

h3,
h4,
h6,
p {
  margin: 0;
}

.App {
  background: #efefef;
}
